document.addEventListener('DOMContentLoaded', () => {
  if (self.innerWidth <= 992) return

  const block = document.querySelector('.events-block'),
        star = block ? block.querySelector('.events-block--header-image') : null,
        stickyElement = block ? block.querySelector('.events-block--background') : null

  if (!block || block.matches('.no-events')) return

  if (star) {
    const checkStickyStar = () => {

      const blockRect = block.getBoundingClientRect(),
            starRect = star.getBoundingClientRect(),
            starParentRect = star.parentElement.getBoundingClientRect(),
            starMarginTop = (starRect.height - starParentRect.height) / 2,
            starInitialLeft = starParentRect.left + 30,
            bodyRect = document.body.getBoundingClientRect(),
            initialPosition = blockRect.top - bodyRect.top - starMarginTop - 150,
            scrolled = document.documentElement.scrollTop

      if (scrolled >= (initialPosition)) {
        star.classList.add('sticky')
        star.style.left = `${starInitialLeft}px`
      } else {
        star.classList.remove('sticky')
        star.style.removeProperty('left');
      }

    }

    checkStickyStar()

    window.addEventListener('scroll', checkStickyStar)
    window.addEventListener('resize', checkStickyStar)
  }

  if (stickyElement) {
    const checkSticky = () => {

      const elementRect = block.getBoundingClientRect(),
            bodyRect = document.body.getBoundingClientRect(),
            initialPosition = elementRect.top - bodyRect.top,
            scrolled = document.documentElement.scrollTop

      scrolled >= (initialPosition) ? stickyElement.classList.add('sticky') : stickyElement.classList.remove('sticky')

    }

    checkSticky()

    window.addEventListener('scroll', checkSticky)
    window.addEventListener('resize', checkSticky)
  }


  const loadMoreButton = document.getElementById('viewMoreEvents'),
        container = document.querySelector('.events-block .events');


  const loadMore = async (paged) => {
    const url = `/wp-json/wp/v2/more-events?paged=${paged}`;
    const response = await fetch(url);
    const data = await response.json();

    return new Promise(resolve => {
      setTimeout(() => {
        resolve(data);
      }, 100);
    });
  }

  const onLoadMoreClicked = async () => {
    const result = await loadMore(loadMoreButton.dataset.paged);
    container.insertAdjacentHTML('beforeend', result.posts);

    if (result.load_more) {
      loadMoreButton.dataset.paged = result.load_more
    } else {
      loadMoreButton.classList.add('hidden')
    }

    console.log(result.load_more)
  }
  if (loadMoreButton) loadMoreButton.addEventListener('click', onLoadMoreClicked);
})


